import { tw } from '@/utils/tw';
import BasePaginationTheme from 'base/components/Pagination/theme';

const Icon = tw.theme({
  extend: BasePaginationTheme.Icon,
  base: `bg-light-sand-500 [&_path]:fill-black`,
});

const Pagination = tw.theme({
  extend: BasePaginationTheme,
  slots: {
    button: `pt-2.5 text-button-md data-[active=true]:bg-black data-[active=true]:text-white sm:text-button-md`,
    dots: `text-button-md sm:text-button-md`,
  },
});

export default Object.assign(Pagination, { Icon });
